import React from "react";
import Style from "./PartnerRow.module.css";
import { Container, Row } from "react-bootstrap";

/* iMG */
import LogoLyopay from "../../Assets/img/lyopay.png";
import LogoProtocol33 from "../../Assets/img/protocol33.png";
import LogoLyotrade from "../../Assets/img/lyotrade.png";
import LogoInvestment from "../../Assets/img/investment.png";
import LogoSubhosting from "../../Assets/img/subhosting.png";

const PartnerRow = () => {
  return (
    <Container>
      <Row>
        <div className={Style.header}>
          <h2>
            Ze
            <span className={Style.evidence}>nit Wor</span>
            ld’s Partners
          </h2>
          <p>
            Risk and performance management, integrated unified communication
            solutions, digital assets projects Incubator and Accelerator: check out who
            our partners are.
          </p>
        </div>

        <div className={Style.partnerBox}>
          <div className={Style.imgBoxOne}>
            <img src={LogoProtocol33} alt="" />
            <img style={{ marginTop: '28px', height: '40px' }} src={LogoLyopay} alt="" />
            <img src={LogoLyotrade} alt="" />
          </div>

          <div className={Style.imgBoxTwo}>
            <img src={LogoInvestment} alt="" />
            <img src={LogoSubhosting} alt="" />
          </div>
        </div>
      </Row>
    </Container>
  );
};

export default PartnerRow
