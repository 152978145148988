import React from 'react'
import Style from './LeadTokenomicsRight.module.css'
import Coins from '../assets/zenit-coins.png'

import ZenWrite from '../assets/zenWrite.png'
import Shape from '../assets/shape.png'

const LeadTokenomicsRight = () => {
    return (
        <div className={Style.coins}>
            <img src={Coins} alt="coins" />
            <div className={Style.write}>
                <img src={ZenWrite} alt="" />
            </div>
            <div className={Style.shape}>
                <img src={Shape} alt="" />
            </div>
        </div>
    )
}

export default LeadTokenomicsRight