import React from "react";

// Components
import { Container, Row, Col } from "react-bootstrap";
import GeneralButton from "../Button/GeneralButton";
// import { Link } from "react-router-dom";

// Assets
import HeroFuture from "../../Assets/img/heroFuture.png";
import Line from "../../Assets/img/line.svg";
import HeroCurve from "../../Assets/img/heroCurve.png";

// Styles
import Style from "./HeroAnimation.module.css";


const HeroAnimation = (props) => {
  return (
    <>
      <Container>
        <Row>
          <Col md={12} lg={12} className={Style.center}>
            <div>
              <div className={Style.header}>
                <h1>
                  Welcome to Zenit World <br /><span className={Style.evidence}> at FBS 2022!</span>
                </h1>
                <p>
                  We are pleased to welcome you to Zenit during the unprecedented Future Blockchain Summit 2022 exhibition. Now you have an exclusive and unparalleled opportunity to participate in the private sale reserved for those who, like you, are taking part in the event. Enter your email in our form now and become part of our Ecosystem.
                </p>
              </div>
              <div className={Style.buttons}>
                <img className={Style.line} src={Line} alt="" />
                <GeneralButton
                  onClick={() => {
                    document.getElementById('form').scrollIntoView({ behavior: 'smooth' });
                  }}
                  buttonText="Go to the form"
                  customStyle={{
                    border: "1px solid #01d085",
                    borderRadius: "100px",
                    color: "white",
                    boxShadow: "0px 20px 60px rgba(0, 208, 133, 0.15)",
                    padding: "15px 30px",
                    fontWeight: "600",
                    fonSize: "18px",
                    lineHeight: "27px",
                    letterSpacing: "-0.5px",
                  }}
                />
              </div>
            </div>
          </Col>

        </Row>
        <Row>
          <Col md={12}>
            <div className={Style.project}>
              <div>
                <span>
                  <img
                    className={Style.logo}
                    src={HeroFuture}
                    alt="hero"
                  />
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <div className={Style.curve}>
        <img className={Style.curveImg} src={HeroCurve} alt="" />
      </div>
    </>
  );
};

export default HeroAnimation;
