import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import Style from "./GeneralRow.module.css";

const GeneralRow = ({ header, leftBox, rightBox }) => {
  return (
    <div className={Style.mainContainer}>
      <Container>
        {header && (
          <div className={Style.header}>
            <>
              {header.supHeader && (
                <div className={Style.supHeader}>
                  <h5>{header.supHeader}</h5>
                </div>
              )}
              {header.headerOne && <h2>{header.headerOne}</h2>}
            </>
          </div>
        )}
        <Row>
          <Col
            xs={
              leftBox.xs
                ? leftBox.xs
                : leftBox.sm
                  ? leftBox.sm
                  : leftBox.md
                    ? leftBox.md
                    : leftBox.lg
                      ? leftBox.lg
                      : leftBox.xl
                        ? leftBox.xl
                        : 6
            }
            sm={
              leftBox.sm
                ? leftBox.sm
                : leftBox.md
                  ? leftBox.md
                  : leftBox.lg
                    ? leftBox.lg
                    : leftBox.xl
                      ? leftBox.xl
                      : leftBox.xs
                        ? leftBox.xs
                        : 6
            }
            md={
              leftBox.md
                ? leftBox.md
                : leftBox.lg
                  ? leftBox.lg
                  : leftBox.xl
                    ? leftBox.xl
                    : leftBox.sm
                      ? leftBox.sm
                      : leftBox.xs
                        ? leftBox.xs
                        : 6
            }
            lg={
              leftBox.lg
                ? leftBox.lg
                : leftBox.xl
                  ? leftBox.xl
                  : leftBox.md
                    ? leftBox.md
                    : leftBox.sm
                      ? leftBox.sm
                      : leftBox.xs
                        ? leftBox.xs
                        : 6
            }
            xl={
              leftBox.xl
                ? leftBox.xl
                : leftBox.lg
                  ? leftBox.lg
                  : leftBox.md
                    ? leftBox.md
                    : leftBox.sm
                      ? leftBox.sm
                      : leftBox.xs
                        ? leftBox.xs
                        : 6
            }
          >
            {leftBox.body}
          </Col>
          <Col
            xs={
              rightBox.xs
                ? rightBox.xs
                : rightBox.sm
                  ? rightBox.sm
                  : rightBox.md
                    ? rightBox.md
                    : rightBox.lg
                      ? rightBox.lg
                      : rightBox.xl
                        ? rightBox.xl
                        : 6
            }
            sm={
              rightBox.sm
                ? rightBox.sm
                : rightBox.md
                  ? rightBox.md
                  : rightBox.lg
                    ? rightBox.lg
                    : rightBox.xl
                      ? rightBox.xl
                      : rightBox.xs
                        ? rightBox.xs
                        : 6
            }
            md={
              rightBox.md
                ? rightBox.md
                : rightBox.lg
                  ? rightBox.lg
                  : rightBox.xl
                    ? rightBox.xl
                    : rightBox.sm
                      ? rightBox.sm
                      : rightBox.xs
                        ? rightBox.xs
                        : 6
            }
            lg={
              rightBox.lg
                ? rightBox.lg
                : rightBox.xl
                  ? rightBox.xl
                  : rightBox.md
                    ? rightBox.md
                    : rightBox.sm
                      ? rightBox.sm
                      : rightBox.xs
                        ? rightBox.xs
                        : 6
            }
            xl={
              rightBox.xl
                ? rightBox.xl
                : rightBox.lg
                  ? rightBox.lg
                  : rightBox.md
                    ? rightBox.md
                    : rightBox.sm
                      ? rightBox.sm
                      : rightBox.xs
                        ? rightBox.xs
                        : 6
            }
          >
            {rightBox.body}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default GeneralRow
