import React, { useState } from "react";

// Icons
import { FiAlertTriangle } from "react-icons/fi";
import { AiOutlineCheck } from "react-icons/ai";
import { HiOutlineEye } from "react-icons/hi";

// Utils
import classNames from "../../Utils/classNames";

// Styles
import Style from "./InputField.module.css";

const InputField = ({
  className,
  error = { value: null },
  errorIcon = true,
  label,
  onPaste = true,
  onCopy = true,
  placeholder,
  required,
  setValue,
  style,
  successIcon = true,
  type,
  validationOnBlur = true,
  validationOnInput = false,
  value,
}) => {
  // Variables
  const [inputType, setInputType] = useState(type);
  const [eyeIcon, setEyeIcon] = useState(false);

  return (
    <div
      className={Style.componentContainer}
      onMouseOver={() => {
        if (type === "password") {
          setEyeIcon(true);
        }
      }}
      onMouseLeave={() => {
        if (type === "password") {
          setEyeIcon(false);
        }
      }}
    >
      {label && <div className={Style.label}>{label}</div>}
      <div
        className={classNames(
          error.value === null
            ? Style.inputFieldContainerEmpty
            : error.value === false
              ? Style.inputFieldContainerError
              : Style.inputFieldContainer,
          className
        )}
        style={style}
      >
        {/* Icon and Input */}
        <span className={Style.inputClass}>
          <input
            className={Style.input}
            type={inputType}
            placeholder={placeholder}
            value={value}
            // onInput Function
            onInput={(e) => {
              if (validationOnInput) {
                setValue(e.target.value);
              } else {
                setValue(e.target.value, false);
              }
            }}
            // onBlur Function
            onBlur={(e) => {
              if (validationOnBlur) {
                setValue(e.target.value);
              }
            }}
            // onPaste Function
            onPaste={(e) => {
              if (!onPaste) {
                e.preventDefault();
              }
            }}
            // onCopy Function
            onCopy={(e) => {
              if (!onCopy) {
                e.preventDefault();
              }
            }}
          />
        </span>

        {/* Status icon */}
        {eyeIcon === true ? (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (inputType === "password") {
                setInputType("text");
              } else {
                setInputType("password");
              }
            }}
          >
            <HiOutlineEye />
          </span>
        ) : (
          <span>
            {error.value === true
              ? successIcon === true &&
              required && <AiOutlineCheck className={Style.successIcon} />
              : error.value === false &&
              errorIcon === true && (
                <FiAlertTriangle className={Style.alertIcon} />
              )}
          </span>
        )}
      </div>


      <div className={Style.errorMessage}>{error.message && error.message}</div>
    </div>
  );
};

export default InputField;
