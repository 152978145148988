import React from "react";

// Components 
import HeroAnimation from "./Homepage/HeroAnimation/HeroAnimation"
import PartnerRow from "./Homepage/PartnerRow/PartnerRow";
import GeneralRow from "./Homepage/GeneralRow/GeneralRow";
import Form from "./Homepage/Form/Form";
import LeadTokenomicsLeft from "./Homepage/GeneralRowComponents/LeadTokenomics/LeadTokenomicsLeft/LeadTokenomicsLeft"
import LeadTokenomicsRight from "./Homepage/GeneralRowComponents/LeadTokenomics/LeadTokenomicsRight/LeadTokenomicsRight"

//Images
import RightShere from "./Assets/img/rightCircle.png";
import Dubai from "./Assets/img/dubai.png";
import LeftShere from "./Assets/img/leftCircle.png";
import CoinTwo from "./Assets/img/coin2.png";
import CoinOne from "./Assets/img/coin1.png";
import CoinThree from "./Assets/img/coin3.png";
import Logo from "./Assets/img/logo.png";

// Style 
import Style from "./App.module.css";

const leadTokenomicsData = {
  leftBox: {
    sm: 12,
    lg: 6,
    body: <LeadTokenomicsLeft />,
  },
  rightBox: {
    sm: 12,
    lg: 6,
    body: <LeadTokenomicsRight />,
  },
};



function App() {
  return (
    <div className="App">
      <main>
        <section className={Style.heroHome}>
          <img src={RightShere} className={Style.rightShere} alt="" />
          <img src={LeftShere} className={Style.leftShere} alt="" />
          <img src={Dubai} className={Style.dubai} alt="" />
          <img src={CoinTwo} className={Style.coin2} alt="" />
          <img src={CoinOne} className={Style.coin1} alt="" />
          <img src={CoinThree} className={Style.coin3} alt="" />
          <img src={Logo} className={Style.logo} alt="" />
          <HeroAnimation />
        </section>
        {/* <div className={Style.curve}>
          <img className={Style.curveImg} src={HeroCurve} alt="" />
        </div> */}
        <section className={Style.tokenomicsBg}>
          <GeneralRow label="LeadTokenomics" {...leadTokenomicsData} />
        </section>
        <section className={Style.form} id='form'>
          <Form />
        </section>
        <section className={Style.partnerRow}>
          <PartnerRow />
        </section>
      </main>
    </div>
  );
}

export default App;
