import React from "react";
import Style from "./LeadTokenomicsLeft.module.css";

const LeadTokenomicsLeft = () => {
  return (
    <>
      <div className={Style.title}>
        <div>
          <h2>
            What is <span className={Style.evidence}>ZEN?</span>
          </h2>
          <p>
            It is a deflationary asset that aims to represent the growth of the ecosystem through the company's ability to generate rewards. The Zen token allows users to access all the services and benefits of the Zenit World Ecosystem.
          </p>

        </div>
      </div>
    </>
  );
};

export default LeadTokenomicsLeft;
