import React, { useState, useEffect } from 'react'

// Libraires
import { Container, Row } from 'react-bootstrap';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'

// Components
import InputField from '../InputField/InputField';
import Button from '../ButtonForm/Button';

// Utils
import useForm from '@hybris-software/use-ful-form'
import useQuery from '@hybris-software/use-query'

// Styles
import 'react-phone-number-input/style.css'
import Style from "./Form.module.css";

const Form = () => {
    const [timer, setTimer] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        if (timer !== null) {
            setTimeout(() => {
                setTimer(oldValue => oldValue - 1)
                if (timer === 1) {
                    window.location.reload();
                }
            }, 1000)
        }
    }, [timer])

    const form = useForm({
        fullName: {
            required: true,
            apiName: "full_name"
        },
        email: {
            required: true,
            type: 'email',
        },
        phone: {
            required: false,
        }
    })

    const query = useQuery({
        url: "landingpage/contactform/",
        executeImmediately: false,
        method: "POST",
        onSuccess: (data) => {
            const thank = document.getElementById("success");
            thank.style.display = "flex";
            setTimer(10);
        },
        onError: (error) => {
            setErrorMessage("Something went wrong, please try again later")
        }
    })

    return (
        <Container>
            <Row>
                <div className={Style.box}>
                    <h2>Join Zenit's p<span className={Style.evidence}>rivate sale now</span>!</h2>
                    <p>By entering your email in our form you will immediately join Zenit and have access to a world of crypto-themed opportunities. Among them, you will have the more unique than rare opportunity to participate in our private sale!  Enter your email here if you would like more information.</p>
                    <div className={Style.form}>

                        <div id='success' className={Style.success}>
                            <p>Thank you for your interest in Zenit. We will contact you shortly.</p>
                            <p className={Style.countdown}>The page will reload in {timer} seconds</p>
                        </div>

                        <h3>Your Contacts</h3>

                        {/* input */}
                        <InputField
                            validationOnInput={true}
                            label={"Full Name"}
                            placeholder="Your full name"
                            {...form.getInputProps("fullName")}
                        />
                        <InputField
                            validationOnInput={true}
                            label={"Email"}
                            placeholder="email@mail.com"
                            {...form.getInputProps("email")}
                        />
                        <div className={Style.label}>Telephone</div>
                        <PhoneInput
                            international
                            placeholder="Enter phone number"
                            value={form.getInputProps("phone").value}
                            onChange={form.getInputProps("phone").setValue}
                            error={form.getInputProps("phone").value ? (isValidPhoneNumber(form.getInputProps("phone").value) ? undefined : 'Invalid phone number') : 'Phone number required'}
                        />


                        <Button
                            disabled={!form.isFormValid()}
                            isLoading={query.isLoading}
                            onClick={() => {
                                setErrorMessage(null)
                                query.executeQuery(form.getApiBody())
                            }}
                            style={{
                                width: "150px",
                                marginTop: "40px",
                            }}
                        >
                            Send
                        </Button>
                        <div className={Style.error}>{errorMessage}</div>
                    </div>
                </div>
            </Row>
        </Container>
    )
}

export default Form