import React, { useState } from "react";
import Style from "./GeneralButton.module.css";

const GeneralButton = (props) => {
  const [text, setText] = useState(props.buttonText);

  return (
    <button
      className={Style.mainContainer}
      style={props.customStyle}
      onClick={() => {
        props.onClick();
      }}
    >
      {props.iconLeft && (
        <span style={props.customStyleIconLeft}>{props.iconLeft}</span>
      )}
      <span
        onMouseOver={() => {
          if (props.comingSoon === true) {
            setText("Coming Soon");
          }
        }}
        onMouseLeave={() => {
          if (props.comingSoon === true) {
            setText(props.buttonText);
          }
        }}
      >
        {text}
      </span>
      {props.iconRight && (
        <span style={props.customStyleIconRight}>{props.iconRight}</span>
      )}
    </button>
  );
};

export default GeneralButton
